import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import logo from "../Assets/Image/gsda_logo.jpeg";
import * as Action from "../actions/authActions";
import * as ActionAdmin from "../actions/adminAction";

const Login = ({
  userDetails,
  adminDetails,
  login,
  sendOtp,
  verifyOtp,
  getmaintenancedata,
  setActiveList,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [loginWithEmail, setLoginWithEmail] = useState(false);
  const [sendOtpForSignUp, setSendOtpForSignUp] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(true);
  const [error, setError] = React.useState();
  const [success, setSuccess] = React.useState(false);
  const [otpSuccess, setOtpSuccess] = React.useState(false);
  const [otpFailure, setOtpFailure] = React.useState(false);
  const [otpVSuccess, setOtpVSuccess] = React.useState(false);
  const [InvaliidNumber, setInvaliidNumber] = React.useState(false);
  const [InvalidPassword, setInvalidPassword] = React.useState(false);
  const [NullPassword,setNullPassword] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  useEffect(() => {
    console.log("userDetails", userDetails);

    if (userDetails && userDetails?.userType[0] === "admin") {
      console.log("userDetails ADMIN", userDetails);
      navigate("/admin/dashboard");
      setActiveList("/admin/dashboard");
    } else if (userDetails && userDetails?.userType[0] === "dc") {
      console.log("userDetails DC", userDetails);
      navigate("/district-coordinator/rollout-plan");
      setActiveList("/district-coordinator/rollout-plan");
    } else if (userDetails && userDetails?.userType[0] === "qc") {
      console.log("userDetails QC", userDetails);
      navigate("/QualityChecker/view-assign-district");
      setActiveList("/QualityChecker/view-assign-district");
    } else if (userDetails && userDetails?.userType[0] === "trainer") {
      console.log("userDetails TRAINER", userDetails);
      navigate("/trainerLayout/rolloutPlan");
      setActiveList("/trainerLayout/rolloutPlan");
    } else if (userDetails && userDetails?.userType[0] === "client") {
      console.log("userDetails CLIENT", userDetails);
      navigate("/clientLogin/rolloutPlan");
      setActiveList("/clientLogin/rolloutPlan");
    } else {
      navigate("/");
    }
  }, [userDetails]);

  const handleClick = async (e) => {
    setSpinner(true);
    e.preventDefault();
    if (contact.length === 10 && password.length > 2 && !loginWithEmail) {
      const result = await login({
        contact: contact,
        password: password,
      });
      if (result && result.errors) {
          console.log(result)
          setError(result.errors);
          setSpinner(false);
          if(result.errors === "No user found"){
            setOtpFailure(true)
          }else if( result.errors === 'Password incorrect' ){
            setInvalidPassword(true)
          }
        // }
      }
       else {
        setSuccess(true);
        setSpinner(false);
        console.log("Result::::", result);
        if (result && result?.user.user.userType[0] === "admin") {
          navigate("/admin/dashboard");
          // setActiveList("/admin/dashboard")
        } else if (result && result?.user.user.userType[0] === "dc") {
          navigate("/district-coordinator/rollout-plan");
          // setActiveList("/district-coordinator/rollout-plan")
        } else if (result && result?.user.user.userType[0] === "qc") {
          navigate("/QualityChecker/view-assign-district");
          // setActiveList("/QualityChecker")
        } else if (result && result?.user.user.userType[0] === "trainer") {
          navigate("/trainerLayout/rolloutPlan");
          // setActiveList("/trainerLayout/rolloutPlan")
        } else if (result && result?.user.user.userType[0] === "client") {
          navigate("/clientLogin/rolloutPlan");
          // setActiveList("/trainerLayout/rolloutPlan")
        } else {
          navigate("/");
        }
      }
      console.log("Login result", result);
    } else if (contact.length === 10 && loginWithEmail) {
      setSendOtpForSignUp(true);
      const result = await sendOtp(contact);
      console.log(result.message);
      if (result.message === "no not register") {
        setOtpFailure(true);
        setSpinner(false);
      } else if (result && result.errors) {
        // setOpen(true);
        setError(result.errors);
      } else {
        setOtpSuccess(true);
        setSpinner(false);
      }
    } else {
      setSpinner(false)
      if (contact.length !== 10) {
        setInvaliidNumber(true)
        console.log("Invalid Number")
      }
      else {
       if(password.length === 0){
        setNullPassword(true)
       }else{
        setInvalidPassword(true);
        console.log("Invalid Password");
       }

      }
    }
  };

  const verifyOtoForSignUp = async (e) => {
    setSpinner(true);
    e.preventDefault();
    if (contact.length === 10 && otp.length === 4) {
      const result = await verifyOtp({
        contactNo: contact,
        newLoginOtp: otp,
      });
      if (result && result.errors) {
        setOtpVSuccess(true);
        setError(result.message);
      } else {
        setOtpVSuccess(true);
        setSpinner(false);
        setError(result.message);
      }
    }
  };

  const changeToLogin = (e) => {
    e.preventDefault();
    setLoginWithEmail(!loginWithEmail);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
    setOtpSuccess(false);
    setSuccess(false);
    setOtpVSuccess(false);
    setOtpFailure(false);
    setSendOtpForSignUp(false);
    setInvaliidNumber(false)
    setInvalidPassword(false)
    setNullPassword(false)
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div className={classes.root}>
      <Stack sx={{ display: "flex", flexDirection: "row" }}>
        <Stack
          sx={{ display: { xs: "none", sm: "block" } }}
          style={{ flex: 1, flexDirection: "column" }}
        ></Stack>
        <div
          style={{
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: 400,
            flex: 1,
          }}
        >
          <form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
                marginTop: 100,
              }}
            >
              <div
                onClick={() => navigate("/")}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{ width: 200, marginLeft: 15 }}
                  alt="React"
                  src={logo}
                />
                <Typography
                  style={{ fontSize: 34, color: "green", fontWeight: 600 }}
                >
                  GSDA
                </Typography>
              </div>
            </div>
            {loginWithEmail ? (
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5%",
                  marginBottom: "1rem",
                }}
              >
                <TextField
                  required
                  error={contact.length <= 10}
                  helperText={
                    contact.length <= 9 || contact.match(/[^0-9]/g)
                      ? "Please enter valid mobile No."
                      : ""
                  }
                  variant="outlined"
                  placeholder="Mobile No."
                  inputProps={{ maxLength: 10, pattern: "[0-9]{0,10}" }}
                  className={classes.input}
                  value={contact}
                  onChange={(e) =>
                    setContact(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                {sendOtpForSignUp && otpFailure === false ? (
                  <TextField
                    required
                    error={otp.length <= 4}
                    helperText={
                      otp.length <= 3 || contact.match(/[^0-9]/g)
                        ? "Please enter valid One Time Password"
                        : ""
                    }
                    variant="outlined"
                    type={showPassword ? "password" : "text"}
                    name="otp"
                    autoComplete="off"
                    placeholder="One Time Password"
                    className={classes.input}
                    inputProps={{ maxLength: 4 }}
                    value={otp}
                    onChange={(e) =>
                      setOtp(e.target.value.replace(/[^0-9]/g, ""))
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : null}
              </div>
            ) : null}
            {!loginWithEmail ? (
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "5%",
                  marginBottom: "1rem",
                }}
              >
                <TextField
                  required
                  error={contact.length !== 10 && contact.length !== 0}
                  inputProps={{ maxLength: 10, pattern: "[0-9]{10}" }}
                  helperText={
                    contact.length !== 10 && contact.length !== 0 || contact.match(/[^0-9]/g)
                      ? "Please enter valid mobile No."
                      : ""
                  }
                  variant="outlined"
                  placeholder="Mobile no."
                  className={classes.input}
                  value={contact}
                  onChange={(e) =>
                    setContact(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                <TextField
                  required
                  error={password.length <= 2 && password.length !== 0}
                  helperText={
                    password.length <= 2 && password.length !== 0
                      ? "Password should be greater than 2 character"
                      : ""
                  }
                  variant="outlined"
                  type={showPassword ? "password" : "text"}
                  name="password"
                  autoComplete="on"
                  placeholder="Password"
                  className={classes.input}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e) => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            ) : null}
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginLeft: "5%",
              }}
            >
              {loginWithEmail && !sendOtpForSignUp ? (
                <Button className={classes.button} onClick={handleClick}>
                  Send OTP
                </Button>
              ) : null}
              {!loginWithEmail ? (
                <Button className={classes.button} onClick={handleClick}>
                  {spinner ? <CircularProgress color="success" /> : "Login"}
                </Button>
              ) : null}
              {loginWithEmail && sendOtpForSignUp ? (
                <Button className={classes.button} onClick={verifyOtoForSignUp}>
                  Verify OTP
                </Button>
              ) : null}
              {loginWithEmail && sendOtpForSignUp && (
                <Button
                  className={classes.button}
                  onClick={() => {
                    setSendOtpForSignUp(false);
                    setLoginWithEmail(false);
                  }}
                >
                  Back
                </Button>
              )}
            </div>
          </form>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Invalid Credentials !
            </Alert>
          </Snackbar>
          <Snackbar open={InvaliidNumber} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
             Enter Valid Mobile Number !
            </Alert>
          </Snackbar>
          <Snackbar open={InvalidPassword} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Invalid Password !
            </Alert>
          </Snackbar>
          <Snackbar open={NullPassword} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Please Enter Valid Password !
            </Alert>
          </Snackbar>
          <Snackbar
            open={success}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Login successfully.
            </Alert>
          </Snackbar>
          <Snackbar
            open={otpSuccess}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              OTP will send to register mobile number
            </Alert>
          </Snackbar>
          <Snackbar
            open={otpFailure}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Your Number is not Registered. Kindly please contact to the
              support team.
            </Alert>
          </Snackbar>
          <Snackbar
            open={otpVSuccess}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={error && error.startsWith("Not") ? "error" : "success"}
              sx={{ width: "100%" }}
            >
              {error && error.startsWith("Not")
                ? error
                : "Sign up successfully."}
            </Alert>
          </Snackbar>
        </div>
        <Stack
          sx={{ display: { xs: "none", sm: "block" } }}
          style={{ flex: 1, flexDirection: "column" }}
        ></Stack>
      </Stack>
      <div style={{ marginTop: 20 }}>
        <Typography
          variant="body-2"
          component="div"
          style={{
            color: "white",
            position: "relative",
            left: 0,
            right: 0,
            bottom: 0,
            textAlign: "center",
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          Powered By{" "}
          <a
            style={{
              color: "white",
              textAlign: "center",
              marginTop: 8,
              marginBottom: 8,
            }}
            href="https://www.foxberry.in/"
            target="_blank"
          >
            {" "}
            Foxberry Technologies{" "}
          </a>{" "}
          &copy; {new Date().getFullYear()}
        </Typography>
        {/* <Typography variant='body-2' component='div' style={{
                    color: 'white',
                    position: "relative", left: 0, right: 0, bottom: 0, textAlign: "center", marginTop: 8, marginBottom: 8
                }}>
                    <a href='tel:9067688688' style={{ color: 'white', textAlign: "center", marginTop: 8, marginBottom: 8 }} >
                        <strong><SupportAgentIcon style={{ fontSize: 18, marginTop: 10 }} />  Support</strong>
                    </a>
                </Typography> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    login: (data) => dispatch(Action.login(data)),
    setActiveList: (data) => dispatch(ActionAdmin.setActiveList(data)),
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    width: "100%",
    background: "#69a6e2",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    height: "100%",
    margin: "0 auto",
    padding: "20px",
    marginTop: "10vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "rgba(255,255,255,0)",
    borderRadius: "20px",
  },
  heading: {
    width: "100%",
    textAlign: "center",
  },
  input: {
    width: "100%",
    padding: "10px 20px",
    margin: "16px !important",
    background: "white",
    borderWidth: "1px",
    borderRadius: "10px !important",
  },
  button: {
    background: "#FFD401 !important",
    fontWeight: "bold !important",
    height: "40px",
    width: "40%",
    borderRadius: "10px !important",
    marginTop: "0.5rem !important",
    border: "1px solid black !important",
  },
}));

export default connect(mapStateToProps, mapStateToDispatch)(Login);
