import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, CircularProgress, Autocomplete, Input } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState } from 'react';
import dayjs from 'dayjs';
// import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import * as XLSX from 'xlsx'
import { toast, ToastContainer } from 'react-toastify';

const shiftArray = [
    {
        label: 'morning',
        value: 'morning'
    },
    {
        label: 'evening',
        value: 'evening'
    },
]

export const AssignTrainer = ({ getAllTrainers, getAllTrainingModuleData, getDistrictWiseTalukaData, getAllDistrictData, getTalukaWisePanchayat, AssignTrainertoPanchayat, userDetails, setActiveList, getSingleTrainerData, DistrictWisePanchayat, BulkTrainingInsert }) => {
    const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const classes = useStyles()
    const navigate = useNavigate()
    const [imgloader, setImgLoader] = useState(false)

    // Array Variable
    const [cityArray, setCityArray] = useState([]);
    const [talukaArray, setTalukaArray] = useState([]);
    const [gramPanchayatArray, setGramPanchayatArray] = useState([])
    const [moduleArray, setModuleArray] = useState([])


    //data variables
    const [city, setCity] = useState('')
    const [taluka, setTaluka] = useState('')
    const [panchayat, setPanchayat] = useState('')
    const [moduleType, setModuleType] = useState('')
    const [trainerData, setTrainerData] = useState([])
    const [loader, setLoader] = useState(false)
    const [date, setDate] = useState(new Date())
    const [shift, setShift] = useState('')
    const [SelectedTrainer, setSelectedTrainer] = useState('')
    const [searchTrainer, setsearchTrainer] = useState('')

    //Bulk Upload
    const [openBulk, setOpenBulk] = useState(false)
    const [bulkDistrict, setBulkDistrict] = useState('')
    const [bulkModule, setBulkModule] = useState('')
    const [bulkData, setBulkData] = useState([])
    const [array, setArray] = useState([])
    const [noGram,setNoGram] = useState(false)

    //Error Variables
    const [cityError, setcityError] = useState(false)
    const [TalukaError, setTalukaError] = useState(false)
    const [PanchayatError, setPanchayatError] = useState(false)
    const [moduleError, setModuleError] = useState(false)
    const [AlreadyAssign, setAlreadyAssign] = useState(false)

    const [SelectcityError, setSelectcityError] = useState(false);
    const [SelectTalukaError, setSelectTalukaError] = useState(false);
    const [SelectPanchyatError, setSelectPanchyatError] = useState(false);
    const [SelectdateError, setSelectdateError] = useState(false);
    const [SelectshiftError, setSelectshiftError] = useState(false);
    const [SelectModuleError, setSelectModuleError] = useState(false)
    const [searchTrainerError, setsearchTrainerError] = useState(false);

    //Bulk Error variables
    const [bulkDistrictError, setBulkDistrictError] = useState(false)
    const [bulkModuleError, setBulkModuleError] = useState(false)
    const [error, setErr] = useState([])
    const [showErr, setShowErr] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    //Table Pagination variables
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //Modal Variables
    const [assign, setAssign] = useState(false)
    const [assignmentFull, setAssignmentFull] = useState(false)

    //toast Error
    const FileUploadError = () => toast.error("Please upload XLSX File Only")

    //useEffects

    React.useEffect(() => {
        getDistrict()
        getModule()
        getTrainerData();
    }, [])

    React.useEffect(() => {
        if (city) {
            getTaluka()
        }
    }, [city])

    React.useEffect(() => {
        if (taluka) {
            getPanchayat()
        }
    }, [taluka])

    React.useEffect(() => {
        if (searchTrainer === null) {
            getTrainerData()
        }
    }, [searchTrainer])

    // Fetching Data
    const getModule = async () => {
        const result = await getAllTrainingModuleData()
        if (result !== 'No data found') {
            setModuleArray(result)
            console.log("ModuleList", result)
        }
    }

    const getDistrict = async () => {
        const result = await getAllDistrictData()
        if (result !== 'No data found') {
            setCityArray(result)
            console.log(result)
        }
    }

    const getTaluka = async () => {
        setTalukaError(false)
        const result = await getDistrictWiseTalukaData({
            distric_id: city?._id
        })
        if (result !== 'No data found') {
            setTalukaArray(result)
            console.log("Taluka list", result)
        } else {
            setTalukaError(true)
        }
    }

    const getPanchayat = async () => {
        setPanchayatError(false)
        const result = await getTalukaWisePanchayat({
            distric_id: city?._id,
            taluka_id: taluka?._id
        })
        if (result !== 'No data found') {
            setGramPanchayatArray(result)
        } else {
            setPanchayatError(true)
        }
    }

    //Table Pagination Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const validate = () => {

        let IsformValid = true;

        if (!city) {
            IsformValid = false;
            setSelectcityError(true)
        }

        if (!taluka) {
            IsformValid = false;
            setSelectTalukaError(true)
        }

        if (!panchayat) {
            IsformValid = false;
            setSelectPanchyatError(true)
        }

        if (!date) {
            IsformValid = false;
            setSelectdateError(true)
        }

        if (!shift) {
            IsformValid = false;
            setSelectshiftError(true)
        }

        if (!moduleType) {
            IsformValid = false;
            setSelectModuleError(true)
        }


        return IsformValid;
    }

    //GET Trianer data
    const getTrainerData = async () => {
        setLoader(true)
        const result = await getAllTrainers()
        if (result) {
            setTrainerData(result)
            console.log(result.filter(item => item?.contactNo.length > 10))
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    const AssignTrainer = () => {
        setCity('')
        setTaluka('')
        setPanchayat('')
        setModuleType('')
        setDate(new Date())
        setShift('')
        setSelectcityError(false)
        setSelectPanchyatError(false)
        setSelectTalukaError(false)
        setSelectdateError(false)
        setSelectshiftError(false)
        setAssign(true)
    }


    const AssignTrainerData = async () => {



        if (validate()) {
            let name = SelectedTrainer?.firstName + " " + SelectedTrainer?.lastName
            console.log("TrainerName", name)
            const result = await AssignTrainertoPanchayat({
                trainer_id: SelectedTrainer?._id,
                trainerName: name,
                trainerMobile: SelectedTrainer?.contactNo,
                trainingModule_id: moduleType?._id,
                trainingModuleName: moduleType?.trainingModuleName,
                distric_id: city?._id,
                districtName: city?.districtName,
                grampanchayat_id: panchayat?._id,
                grampanchayatName: panchayat?.grampanchayatName,
                taluka_id: taluka?._id,
                talukaName: taluka?.talukaName,
                assiggnDate: moment(date?.$d).format("YYYY-MM-DD"),
                shift: shift,
                createdBy: userDetails?._id
            })
            if (result) {
                console.log("Assign Trainer", result)
                if (result === 'Trainer already assign to this shift and date') {
                    setModuleError(true)
                } else if (result === 'Trainer assignment full') {
                    setAssignmentFull(true)
                } else if (result === 'Trainer already assigned') {
                    setAlreadyAssign(true)
                } else {
                    setAssign(false)
                    navigate("/admin/assigned-trainer-list")
                    setActiveList("/admin/assigned-trainer-list")
                }

            } else {
                console.log("Assign Failed")
                setAssign(false)
            }
        } else {
            console.log("Invalid ")
        }
    }

    const getSingleTrainer = async () => {
        console.log("Get Trainer", searchTrainer)
        setsearchTrainerError(false)
        setLoader(true)
        if (searchTrainer) {
            console.log("get")
            const result = await getSingleTrainerData({
                user_id: searchTrainer?._id
            })
            if (result) {
                setTrainerData(result)
                setLoader(false)
            }
        } else {
            setsearchTrainerError(true)
            setLoader(false)
            getTrainerData()
        }
    }

    const bulkValidate = () => {
        let IsformValid = true;

        if (!bulkDistrict) {
            IsformValid = false;
            setBulkDistrictError(true)
        }

        if (!bulkModule) {
            IsformValid = false;
            setBulkModuleError(true)
        }

        return IsformValid;
    }

    const getDistrictData = async () => {
        let arr = []
        arr.push(bulkDistrict?._id)
        const result = await DistrictWisePanchayat({
            "distric_id": arr
        })
        setBulkData(result)
        if(result !== 'No data found'){
            genrateExcel(result)
        }else{
            setNoGram(true)
        }
    }

    const genrateExcel = async (result) => {
        console.log("Excel Data For", result)
        const customHeadings =
            result.length > 0 &&
            result.map((item, index) => ({
                "District": item?.districtName,
                "Taluka": item?.talukaName,
                "GramPanchayat": item?.grampanchayatName,
                "Module": bulkModule?.trainingModuleName,
                "Assignment_Date": "",
                "Shift": "",
                "Trainer_1_Name": "",
                "Triner_1_Mobile": "",
                "Trainer_2_Name": "",
                "Triner_2_Mobile": "",
            }));
        console.log("Customm Excel", customHeadings);
        downloadExcel(customHeadings);
    }

    const downloadExcel = (customHeadings) => {
        let dataSet = [];
        console.log("dataset", dataSet);
        dataSet = customHeadings;
        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "TrainingBulkUpload.xlsx");
    };

    const getExcel = async () => {
        setBulkDistrictError(false)
        setBulkModuleError(false)
        if (bulkValidate()) {
            await getDistrictData()
        } else {
            console.log("Form Invalid")
        }
    }

    const handleChangeExcel = (e) => {
        setImgLoader(true)
        var name = e.target.files[0].name
        console.log("File Name=>>>>", name);
        const ext = name.split(".")[1];
        console.log("Extension==>", ext);
        if (ext === "xlsx") {
            const reader = new FileReader();
            reader.onload = (evt) => { // evt = on_file_select event
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                // Get ContactList worksheet 
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                // Convert array of arrays 
                // const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                const data1 = XLSX.utils.sheet_to_json(wb.Sheets[wsname], { raw: false });
                // Update state 
                setArray(data1)
                console.log("Data>>>", data1);
                validation(data1)
            };
            reader.readAsBinaryString(e.target.files[0]);

        }
        else {
            FileUploadError()
            setLoader(false)
            console.log("Extension ERROR")
        }
        document.getElementById("myInput").value = ""
    }

    const validation = async (data) => {
        console.log("Validating data", data)
        if (data.length > 0) {
            let errorArray = []
            data.map((row, index) => {
                if (!row?.District) {
                    errorArray.push(`Row ${index + 2} Enter District Name`)
                } else if (row?.District.match(/[0-9+@#$&%!~]/)) {
                    errorArray.push(`Row ${index + 2} Invalid District Name`)
                }
                if (!row?.Taluka) {
                    errorArray.push(`Row ${index + 2} Enter Taluka Name`)
                } else if (row?.Taluka.match(/[0-9+@#$&%!~]/)) {
                    errorArray.push(`Row ${index + 2} Invalid Taluka Name`)
                }
                if (!row?.GramPanchayat) {
                    errorArray.push(`Row ${index + 2} Enter GramPanchayat Name`)
                } else if (row?.GramPanchayat.match(/[0-9+@#$&%!~]/)) {
                    errorArray.push(`Row ${index + 2} Invalid GramPanchayat Name`)
                }
                if (!row?.Module) {
                    errorArray.push(`Row ${index + 2} Enter Module Name`)
                } else if (row?.Module.match(/[+@#$%!~]/)) {
                    errorArray.push(`Row ${index + 2} Invalid Module Name`)
                }
                console.log(row?.Assignment_Date.split('-'))
                if (!row?.Assignment_Date) {
                    errorArray.push(`Row ${index + 2} Enter Assignment Date`)
                } else if (!row?.Assignment_Date.split('-').length !== 0 && row?.Assignment_Date.includes("/") === false) {
                    let date = row?.Assignment_Date.split('-').map(Number)
                    console.log("Checking date", date)
                    if (isNaN(date[0])) {
                        console.log("Hello")
                        errorArray.push(`Row ${index + 2} Assignment Date year is not valid`)
                    } else if (date[0] < 2000 || date[0] > 9999) {
                        console.log("tyda")
                        errorArray.push(`Row ${index + 2} Assignment Date year is not valid`)
                    }
                    if (!isNaN(date[1]) && date[1] < 0 || date[1] > 12) {
                        errorArray.push(`Row ${index + 2} Assignment Date month is not valid`)
                    }
                    if (!isNaN(date[2]) && date[2] < 0 || date[2] > 31) {
                        errorArray.push(`Row ${index + 2} Assignment Date day is not valid`)
                    }
                } else {
                    errorArray.push(`Row ${index + 2} Enter Assignment Date in YYYY-MM-DD format`)
                }

                if (!row?.Shift) {
                    errorArray.push(`Row ${index + 2} Enter Shift Name`)
                } else if (row?.Shift.toUpperCase().localeCompare("MORNING") !== 0 && row?.Shift.toUpperCase().localeCompare("EVENING") !== 0) {
                    errorArray.push(`Row ${index + 2}  Shift Name Should be MORNING or EVENING `)
                }

                if (!row?.Trainer_1_Name) {
                    errorArray.push(`Row ${index + 2} Enter Trainer_1_Name`)
                } else if (row?.Trainer_1_Name.match(/[0-9+@#$&%!~]/)) {
                    errorArray.push(`Row ${index + 2} Invalid Trainer_1_Name`)
                }
                if (!row?.Trainer_2_Name) {
                    errorArray.push(`Row ${index + 2} Enter Trainer_2_Name`)
                } else if (row?.Trainer_2_Name.match(/[0-9+@#$&%!~]/)) {
                    errorArray.push(`Row ${index + 2} Invalid Trainer_2_Name`)
                }
                if (!row?.Triner_1_Mobile) {
                    errorArray.push(`Row ${index + 2} Enter Triner_1_Mobile `)
                } else if (row?.Triner_1_Mobile.match(/[A-Za-z+@#$&%!~]/) ||
                    (row?.Triner_1_Mobile.length !== 10 && row?.Triner_1_Mobile.length !== 0)) {
                    errorArray.push(`Row ${index + 2} Invalid Triner_1_Mobile `)
                }
                if (!row?.Triner_2_Mobile) {
                    errorArray.push(`Row ${index + 2} Enter Triner_2_Mobile `)
                } else if (row?.Triner_2_Mobile.match(/[A-Za-z+@#$&%!~]/) ||
                    (row?.Triner_2_Mobile.length !== 10 && row?.Triner_2_Mobile.length !== 0)) {
                    errorArray.push(`Row ${index + 2} Invalid Triner_2_Mobile `)
                }
            })
            console.log(errorArray)
            setErr(errorArray)
            if (errorArray.length > 0) {
                DownloadTextFile(errorArray);
                setShowErr(true)
            } else {
                const result = await BulkTrainingInsert(data)
                console.log(result)
                if(result !== undefined){
                    if (result?.msg === "successful") {
                        setShowSuccess(true)
                    } else {
                        DownloadTextFile(result);
                        setShowErr(true)
                    }
                }else{
                    setImgLoader(false)
                }
            }
        }
        setImgLoader(false)
    }

    const DownloadTextFile = (err) => {

        // var row_width = 40;

        var content = "";
        content += "Following Error Occured in Recent Excel document\n";


        for (var i = 0; i < err.length; i++) {
            content += err[i]
            content += "\n";
        }

        const element = document.createElement("a");
        const file = new Blob([content],
            { type: 'text/plain;charset=utf-8' });
        element.href = URL.createObjectURL(file);
        element.download = "ExecelError.txt";
        document.body.appendChild(element);
        element.click();

        // // Build a data URI:
        // const uri = "data:application/octet-stream," + encodeURIComponent(content);

        // // Click on the file to download
        // // You can also do this as a button that has the href pointing to the data URI
        // window.location.href = uri;
    }

    return (
        <div className={classes.main}>
            <ToastContainer />
            <center>
                <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }} >Assign Panchayat</Typography>
            </center>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop:10 }}>
                <Button variant='contained' onClick={() => setOpenBulk(true)} >Bulk Upload DistrictWise</Button>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                <a style={{ color:'#555657' }} target="_blank" href='https://gsda-images2.s3.ap-south-1.amazonaws.com/gsad/Jalna/Jalna/Wiregaon/1_IEC_&_Community_Mobilization_Program/Government%20official%20visit%20record/63a47c08aa5f45ec3427f65e/1673938645129_.pdf'>*Instructions for Excel upload</a>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 50 }}>
                <div>
                    <Autocomplete
                        id='Trainer-data'
                        sx={{ width: 300 }}
                        options={trainerData}
                        autoHighlight
                        disablePortal
                        freeSolo
                        disabled={trainerData === undefined || trainerData.length === 0}
                        getOptionLabel={(option) => option?.firstName + " " + option?.lastName + " - " + option?.contactNo}
                        renderInput={(params) => (<TextField {...params} label="Select Trainer" />)}
                        // value={searchTrainer}
                        onChange={(event, newValue) => {
                            setsearchTrainer(newValue);
                            // getSingleTrainer(newValue)
                        }}
                    />
                    {searchTrainerError ? <span style={{ color: 'red' }} >*Please Select trainer first</span> : null}
                </div>
                <Button variant='contained' style={{ marginLeft: 5, height: 55 }} onClick={() => getSingleTrainer()} >Get Trainer</Button>
                {/* <Button variant='contained' style={{ marginLeft: 5,height:55 }} onClick={() => getTrainerData()} >Get All Data</Button> */}
            </div>
            {
                loader ? <CircularProgress color="primary" /> : <Paper sx={{ overflow: 'hidden', width: '90%' }}>
                    {/* Table shows data */}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>SR No.</strong></TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Name</strong></TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Mobile</strong></TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Email-Id</strong></TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Address</strong></TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Action</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    trainerData !== 'No data found' && trainerData !== undefined && trainerData.length > 0 ? trainerData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{page * rowsPerPage + (index + 1)}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.firstName} {row?.lastName}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.contactNo}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.emailId ? row?.emailId : '-'}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.currentAddressLine1}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>
                                                    <Button variant='contained' onClick={() => { AssignTrainer(); setSelectedTrainer(row) }}>Assign Panchayat</Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                        : <TableRow>NO Data Found</TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        // count={count ? count : 0}
                        count={trainerData !== "No data found" && trainerData?.length > 0 ? trainerData?.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            }


            <Modal
                className={classes.middlePosition}
                sx={{ marginTop: 18 }}
                open={assign}
                onClose={(e) => {
                    e.preventDefault();
                    setAssign(false)
                }}
            >
                <Paper className={classes.profilemodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Assign Panchayat</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setAssign(false)
                            setDate("")
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 50 }}>
                        {/* City */}
                        <FormControl sx={{ m: 1, marginRight: 3 }}>
                            <InputLabel id="outlined-adornment-Bank">District</InputLabel>
                            <Select
                                style={{ width: 250 }}
                                id="outlined-adornment-Bank"
                                label="District"
                                value={city}
                                onChange={(e) => { setCity(e.target.value); }}
                                // fetchTaluka(e.target.value) 
                                disabled={cityArray === undefined || cityArray?.length === 0}
                                MenuProps={{
                                    style: {
                                        maxHeight: 400,
                                    },
                                }}
                            >
                                {cityArray !== undefined && cityArray?.map((item) => {
                                    return (
                                        <MenuItem key={item?._id} value={item}>
                                            {item?.districtName}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            {cityError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select City</span> : null}
                            {SelectcityError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select City</span> : null}
                        </FormControl>
                        {/* Taluka */}
                        <FormControl sx={{ m: 1, marginRight: 3 }}>
                            <InputLabel id="outlined-adornment-Bank">Taluka</InputLabel>
                            <Select
                                style={{ width: 250 }}
                                id="outlined-adornment-Bank"
                                label="Taluka"
                                value={taluka}
                                onChange={(e) => { setTaluka(e.target.value); }}
                                // fetchGramPanchayat(city, e.target.value);
                                disabled={talukaArray.length === 0 || talukaArray === undefined}
                                MenuProps={{
                                    style: {
                                        maxHeight: 400,
                                    },
                                }}
                            >
                                {talukaArray && talukaArray?.map((item) => {
                                    return (
                                        <MenuItem key={item?._id} value={item}>
                                            {item?.talukaName}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            {TalukaError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Taluka</span> : null}
                            {SelectTalukaError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Taluka</span> : null}
                        </FormControl>
                        {/* Panchayat */}
                        <FormControl sx={{ m: 1, marginRight: 3 }}>
                            <InputLabel id="outlined-adornment-Bank">Panchayat</InputLabel>
                            <Select
                                style={{ width: 250 }}
                                id="outlined-adornment-month"
                                label="Panchayat"
                                value={panchayat}
                                onChange={(e) => { setPanchayat(e.target.value) }}
                                disabled={gramPanchayatArray.length === 0 || gramPanchayatArray === undefined}
                                MenuProps={{
                                    style: {
                                        maxHeight: 400,
                                    },
                                }}
                            >
                                {gramPanchayatArray && gramPanchayatArray?.map((item) => {
                                    return (
                                        <MenuItem key={item?._id} value={item}>
                                            {item?.grampanchayatName}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            {PanchayatError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Panchyat</span> : null}
                            {SelectPanchyatError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Panchyat</span> : null}
                        </FormControl>
                        {/* Module type */}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50 }}>
                        {/* Module type */}
                        <FormControl sx={{ m: 1, marginRight: 3 }}>
                            <InputLabel id="outlined-adornment-Bank">Module</InputLabel>
                            <Select
                                style={{ width: 260 }}
                                id="outlined-adornment-month"
                                label="Module"
                                value={moduleType}
                                disabled={moduleArray === undefined || moduleArray.length === 0}
                                onChange={(e) => { setModuleType(e.target.value) }}
                                MenuProps={{
                                    style: {
                                        maxHeight: 400,
                                    },
                                }}
                            >
                                {moduleArray && moduleArray?.map((item) => {
                                    return (
                                        <MenuItem key={item?._id} value={item}>
                                            {item?.trainingModuleName}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            {SelectModuleError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Module</span> : null}
                        </FormControl>
                        <FormControl sx={{ m: 1, marginRight: 3 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Assignment Date"
                                    value={date}
                                    // minDate={new Date()}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                        // console.log(newValue.$D + "/" + (newValue.$M + 1) + "/" + newValue.$y)
                                    }}
                                    inputFormat={"DD/MM/YYYY"}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            {SelectdateError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Assignment Date</span> : null}
                        </FormControl>

                        <FormControl sx={{ m: 1, marginRight: 3 }}>
                            <InputLabel id="outlined-adornment-Bank">Shift</InputLabel>
                            <Select
                                style={{ width: 250 }}
                                id="outlined-adornment-Bank"
                                label="Shift"
                                value={shift}
                                onChange={(e) => { setShift(e.target.value); }}
                                // disabled={cityArray.length > 0 ? false : true}
                                MenuProps={{
                                    style: {
                                        maxHeight: 400,
                                    },
                                }}
                            >
                                {shiftArray && shiftArray?.map((item) => {
                                    return (
                                        <MenuItem key={item?.label} value={item?.value}>
                                            {item?.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            {SelectshiftError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Shift</span> : null}
                        </FormControl>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Button variant='contained' onClick={() => { AssignTrainerData() }}>Assign Trainer</Button>
                    </div>

                    <div>
                    </div>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={moduleError}
                onClose={(e) => {
                    e.preventDefault();
                    setModuleError(false);
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Trainer already assign to this shift and date</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setModuleError(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <Button variant='contained' onClick={() => setModuleError(false)}>Ok</Button>
                    </center>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={AlreadyAssign}
                onClose={(e) => {
                    e.preventDefault();
                    setAlreadyAssign(false);
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Trainer already Assign to this Module.</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setAlreadyAssign(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <Button variant='contained' onClick={() => {
                            setAlreadyAssign(false);
                            navigate("/admin/assigned-trainer-list");
                            setActiveList("/admin/assigned-trainer-list")
                        }}>Ok</Button>
                    </center>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={assignmentFull}
                onClose={(e) => {
                    e.preventDefault();
                    setAssignmentFull(false);
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Maximum Trainer assignment reached for this module on selected grampanchayat.</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setAssignmentFull(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <Button variant='contained' onClick={() => {
                            setAssignmentFull(false);
                            navigate("/admin/assigned-trainer-list");
                            setActiveList("/admin/assigned-trainer-list")
                        }}>Ok</Button>
                    </center>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={openBulk}
                onClose={(e) => {
                    e.preventDefault();
                    setOpenBulk(false)
                }}
            >
                <Paper className={classes.profilemodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Training Bulk Upload</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setOpenBulk(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10 }}>
                            {/* City */}
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <InputLabel id="outlined-adornment-Bank">District</InputLabel>
                                <Select
                                    style={{ width: 250 }}
                                    id="outlined-adornment-Bank"
                                    label="District"
                                    value={bulkDistrict}
                                    onChange={(e) => { setBulkDistrict(e.target.value); }}
                                    // fetchTaluka(e.target.value)
                                    disabled={cityArray === undefined || cityArray.length === 0}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                >
                                    {cityArray && cityArray?.map((item) => {
                                        return (
                                            <MenuItem key={item?._id} value={item}>
                                                {item?.districtName}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {bulkDistrictError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select City</span> : null}
                            </FormControl>
                            {/* Module type */}
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <InputLabel id="outlined-adornment-Bank">Module</InputLabel>
                                <Select
                                    style={{ width: 250 }}
                                    id="outlined-adornment-month"
                                    label="Module"
                                    value={bulkModule}
                                    disabled={moduleArray === undefined || moduleArray.length === 0}
                                    onChange={(e) => { setBulkModule(e.target.value) }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                >
                                    {moduleArray && moduleArray?.map((item) => {
                                        return (
                                            <MenuItem key={item?._id} value={item}>
                                                {item?.trainingModuleName}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {bulkModuleError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Module</span> : null}
                            </FormControl>
                        </div>
                        <center>
                            <Button variant='contained' onClick={() => getExcel()}>Get Excel Format</Button>
                        </center>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
                            <input className={classes.input} type="file" id="myInput" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" variant="outlined" onChange={(e) => {
                                handleChangeExcel(e)
                                console.log("sdfghj", e)
                            }} style={{ display: "none" }} />
                            <label htmlFor='myInput'>
                                { imgloader ?  <CircularProgress color='primary' />
                                : <IconButton color='primary' aria-label="upload picture" component="span" style={{ borderRadius: '7%', color: 'white', backgroundColor: '#1d5a96', fontSize: 17, fontWeight: 400 }} sx={{
                                    "&:hover": {
                                        backgroundColor: "#f6cc63 !important",
                                        fontSize: 17,
                                    },
                                }} >
                                    Upload Excel
                                </IconButton> }
                            </label>
                        </div>
                    </div>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={showErr}
                onClose={(e) => {
                    e.preventDefault();
                    setShowErr(false)
                }}
            >
                <Paper className={classes.errormodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Found Invalid Content in Excel.Please Correct it and reupload it.</Typography>
                            <Typography style={{ color: 'grey' }}>*Please check out downloaded file for error reference.</Typography>
                        </Stack>
                        <IconButton aria-label="delete" style={{ bottom: 30 }} onClick={e => {
                            e.preventDefault();
                            setShowErr(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={showSuccess}
                onClose={(e) => {
                    e.preventDefault();
                    setShowSuccess(false)
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Bulk Upload Completed Successfully.</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setShowSuccess(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <Button variant='contained' onClick={() => {
                            navigate("/admin/assigned-trainer-list")
                            setActiveList("/admin/assigned-trainer-list")
                        }} >View</Button>
                    </center>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={noGram}
                onClose={(e) => {
                    e.preventDefault();
                    setNoGram(false)
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>No taluka or GramPanchayat found for Selected District.</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setNoGram(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <Button variant='contained' onClick={() => {
                            setNoGram(false)
                        }} >OK</Button>
                    </center>
                </Paper>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '1200px',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    errormodal: {
        width: '600px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '400px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '900px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '100%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setActiveList: (data) => dispatch(Action.setActiveList(data)),
        getAllTrainers: () => dispatch(Action.getAllTrainers()),
        getAllTrainingModuleData: () => dispatch(Action.getAllTrainingModuleData()),
        getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
        getDistrictWiseTalukaData: (data) => dispatch(Action.getDistrictWiseTalukaData(data)),
        getTalukaWisePanchayat: (data) => dispatch(Action.getTalukaWisePanchayat(data)),
        AssignTrainertoPanchayat: (data) => dispatch(Action.AssignTrainertoPanchayat(data)),
        setActiveList: (data) => dispatch(Action.setActiveList(data)),
        getSingleTrainerData: (data) => dispatch(Action.getSingleTrainerData(data)),
        DistrictWisePanchayat: (data) => dispatch(Action.DistrictWisePanchayat(data)),
        BulkTrainingInsert: (data) => dispatch(Action.BulkTrainingInsert(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignTrainer)