import React from "react";
import { Routes, Route } from "react-router-dom";
import Admin from "./layout/Admin";
import Login from "./pages/Login";
import TrainerLayout from "./layout/TrainerLayout";
import Dashboard from "./pages/Admin/Dashboard";
import AssignTrainer from "./pages/Admin/AssignTrainer";
import AssignedTrainerList from "./pages/Admin/AssignedTrainerList";
import AddUpdateTrainerList from "./pages/Admin/AddUpdateTrainerList";
import ApprovedTrainingDocsList from "./pages/Admin/ApprovedTrainingDocsList";
import UploadTraningDocslist from "./pages/Admin/UploadTraningDocslist";
import RollOutPlan from "./pages/Trainer/RollOutPlan";
import Activities from "./pages/Trainer/Activities";
import UploadStatus from "./pages/Trainer/UploadStatus";
import ApprovedTrainer from "./pages/Trainer/ApprovedTrainer";
import RejectedTrainer from "./pages/Trainer/RejectedTrainer";
import ViewTrainerDocs from "./pages/Admin/ViewTrainerDocs";
import EditTrainerDocs from "./pages/Admin/EditTrainerDocs";
import ViewApprovedDocs from "./pages/Admin/ViewApprovedDocs";
import AllGrampanchyatList from "./pages/Admin/AllGrampanchyatList";
import AssignGrampanchyat from "./pages/Admin/AssignModuleCatToGrampanchyat";
import AlldistrictList from "./pages/Admin/AlldistrictList";
import AllTalukaList from "./pages/Admin/AllTalukaList";
import ListUsers from "./pages/Admin/ListUsers";
import RolloutDetails from "./pages/Trainer/RolloutDetails";
import ViewApprovedItem from "./pages/Trainer/ViewApprovedItem";
import ViewRejectedItem from "./pages/Trainer/ViewRejectedItem";
import DistrictCoordinator from "./layout/DistrictCoordinator";
import QualityCheckerLayout from "./layout/QualityCheckerLayout";
import TrainingModule from "./pages/Admin/TrainingModule";
import AddTrainer from "./pages/Admin/AddTrainer";
import AddUser from "./pages/Admin/AddUser";
import EditUser from "./pages/Admin/EditUser";

import AllAssignedTrainer from "./pages/QualityChecker/AllAssignedTrainer";
import UploadedDocument from "./pages/QualityChecker/UploadedDocument";
import ApprovedDocument from "./pages/QualityChecker/ApprovedDocument";
import ViewTrainerdocs from "./pages/QualityChecker/ViewTrainerdocs";
import ViewAllRejectedlist from "./pages/QualityChecker/ViewAllRejectedlist";
import Viewrejecteditem from "./pages/QualityChecker/Viewrejecteditem";
import ViewApproveditemlist from "./pages/QualityChecker/ViewApproveditemlist";
import ViewAssignDistrict from "./pages/QualityChecker/ViewAssignDistrict";
import GrampanchayatTrainerList from "./pages/QualityChecker/GrampanchayatTrainerList";
import Trainerdetails from "./pages/QualityChecker/Trainerdetails";

import { TrainingCategories } from "./pages/Admin/TrainingCategory";
import { EditProfile } from "./pages/Admin/ProfileEdit";
import GrampanchayatDetails from "./pages/DistrictCoordinator/GrampanchayatDetails";
import GpDetailsView from "./pages/DistrictCoordinator/GpDetailsView";
import ViewRejectedItems from "./pages/DistrictCoordinator/ViewRejectedItems";
import ViewApprovedItems from "./pages/DistrictCoordinator/ViewApprovedItems";
import ApprovedTrainingDetails from "./pages/DistrictCoordinator/ApprovedTrainingDetails";
import RejectedTrainingDetails from "./pages/DistrictCoordinator/RejectedTrainingDetails";

import Profile from "./pages/Admin/Profile";
import Beneficial from "./pages/Beneficial";
import Thankyou from "./pages/Thankyou";
import AssignDistricttoQC from "./pages/Admin/AssignDistricttoQC";
import AssignDistrictToDC from "./pages/Admin/AssignDistrictToDC";

import ViewAssignDistrict2 from "./pages/DistrictCoordinator/ViewAssignDistrict2";
import { GrampanchayatTrainerList2 } from "./pages/DistrictCoordinator/GrampanchayatTrainerList2";
import { Trainerdetails2 } from "./pages/DistrictCoordinator/Trainerdetails2";
import ViewApproveditemlistDC from "./pages/DistrictCoordinator/ViewApproveditemlistDC";
import ApprovedDocumentDC from "./pages/DistrictCoordinator/ApprovedDocumentDC";
import RollOutPlanDC from "./pages/DistrictCoordinator/RollOutPlanDC";
import RolloutDetailsDC from "./pages/DistrictCoordinator/RolloutDetailsDC";
import TrainerReassign from "./pages/Admin/TrainerReassign";
import PendingList from "./pages/Admin/PendingList";
import ViewPendingDocs from "./pages/Admin/ViewPendingDocs";
import AddBeneficiary from "./pages/Trainer/AddBeneficiary";
import BeneficiaryListDetails from "./pages/Trainer/BeneficiaryListDetails";
import AddSingleBeneficiary from "./pages/Trainer/AddSingleBeneficiary";
import ClientLogin from '../src/layout/ClientLogin';
import ClientRolloutPlan from "./pages/ClientLogin/ClientRolloutPlan";
import ClientRolloutPlanDetails from "./pages/ClientLogin/ClientRolloutPlanDetails";
import ClientApprovedDoc from "./pages/ClientLogin/ClientApprovedDoc";
import ClientApprovedDocDetails from "./pages/ClientLogin/ClientApprovedDocDetails";
import ClientPendingList from "./pages/ClientLogin/ClientPendingList";
import ClientPendingDocDetails from "./pages/ClientLogin/ClientPendingDocDetails";
import TrainerList from "./pages/ClientLogin/TrainerList";
import NewDistrictList from "./pages/ClientLogin/NewDistrictList";
import BeneficieryListAndCertificates from "./pages/Admin/BeneficieryListAndCertificates";
import ViewBeneficieryList from "./pages/Admin/ViewBeneficieryList";
import AllTrainingReportList from "./pages/Admin/AllTrainingReportList";
import GenrateTrainingReportList from "./pages/Admin/GenrateTrainingReportList"
import CreateReport  from "./pages/Admin/CreateReport"
import ReportForm from "./pages/Admin/ReportForm";
import FeedbackForm from "./pages/Admin/FeedbackForm";
import TrainingReport from "./pages/Trainer/TrainingReport";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/beneficial" element={<Beneficial />} />

        {/* Client Layout */}
        <Route path="/clientLogin" element={<ClientLogin />}>
          <Route path="/clientLogin/rolloutPlan" element={<ClientRolloutPlan />} />
          <Route path="/clientLogin/clientRolloutDetails" element={<ClientRolloutPlanDetails />} />
          <Route path="/clientLogin/approvedDoc" element={<ClientApprovedDoc />} />
          <Route path="/clientLogin/approvedDocDetails" element={<ClientApprovedDocDetails />} />
          <Route path="/clientLogin/clientPendingDocList" element={<ClientPendingList />} />
          <Route path="/clientLogin/clientPendingDocDetails" element={<ClientPendingDocDetails />} />
          <Route path="/clientLogin/district" element={<NewDistrictList/>} />
          {/* <Route path="/clientLogin/district/allGramPanchayat" element={<ViewApprovedItem />} /> */}

          <Route path="/clientLogin/trainerList" element={<TrainerList/>} />
          <Route path="/clientLogin/trainerList/details" element={<ViewRejectedItem/>} />
          
          <Route path="/clientLogin/profile" element={<Profile />} />
        </Route>

        {/* Trainer Layout */}
        <Route path="/trainerLayout" element={<TrainerLayout />}>
          <Route path="/trainerLayout/rolloutPlan" element={<RollOutPlan />} />
          <Route path="/trainerLayout/rolloutDetails" element={<RolloutDetails />} />
          <Route path="/trainerLayout/uploadDocument" element={<Activities />} />
          <Route path="/trainerLayout/addbeneficiary" element={<AddBeneficiary />} />
          <Route path="/trainerLayout/beneficiarylistdetails" element={<BeneficiaryListDetails/>} />
          <Route path="/trainerLayout/addsinglebeneficiary" element={<AddSingleBeneficiary/>} />
          {/*Upload Document */}
          <Route path="/trainerLayout/approvedTrainer" element={<ApprovedTrainer />} />
          <Route path="/trainerLayout/rejectedTrainer" element={<RejectedTrainer />} />
          <Route path="/trainerLayout/viewApprovedItem" element={<ViewApprovedItem />} />
          <Route path="/trainerLayout/viewRejectedItem" element={<ViewRejectedItem />} />
          <Route path="/trainerLayout/profile" element={<Profile />} />
          <Route path="/trainerLayout/training-report" element={ <TrainingReport />} />
        </Route>

        {/* Admin Layout */}
        <Route path="/admin" element={<Admin />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/assign-trainer" element={<AssignTrainer />} />
          <Route path="/admin/profile" element={<Profile />} />
      
          <Route path="/admin/assigned-trainer-list" element={<AssignedTrainerList />}/>
          <Route path="/admin/crud-tainer" element={<AddUpdateTrainerList />} />
          <Route path="/admin/upload-document" element={<UploadTraningDocslist/>}/>
          <Route path="/admin/Approved-document" element={<ApprovedTrainingDocsList />}/>
          <Route path="/admin/view-trainer-docs" element={<ViewTrainerDocs/>}/>
          <Route path="/admin/edit-trainer-docs" element={<EditTrainerDocs/>}/>
          <Route path="/admin/view-approved-docs" element={<ViewApprovedDocs />}/>
          <Route path="/admin/add-user" element={<AddUser />} />
          <Route path="/admin/edit-user" element={<EditUser />} />
          <Route path="/admin/all-panchyat-list" element={<AllGrampanchyatList />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/assign-Panchyat" element={<AssignGrampanchyat />} />
          <Route path="/admin/all-district-list" element={<AlldistrictList />} />
          <Route path="/admin/all-taluka-list" element={<AllTalukaList />} />
          <Route path="/admin/user-list" element={<ListUsers />} />
          <Route path="/admin/training-module" element={<TrainingModule />} />
          <Route path="/admin/training-category" element={<TrainingCategories />} />
          <Route path="/admin/addTrainerModule" element={<AddTrainer />} />
          {/* <Route path="/admin/profile" element={<Profile />} /> */}

          <Route path="/admin/Assign-DistrictQC" element={ <AssignDistricttoQC /> } />
          <Route path="/admin/Assign-DistrictDC" element={ <AssignDistrictToDC /> } />
          <Route path="/admin/Trainer-Reassign" element={ <TrainerReassign /> } /> 
          <Route path="/admin/PendingList" element={ <PendingList /> } />
          <Route path="/admin/View-Pending-doc" element={ <ViewPendingDocs /> } />
          <Route path="/admin/view-Beneficiery-list" element={ <BeneficieryListAndCertificates /> } />
          <Route path="/admin/View-Beneficiery" element={ <ViewBeneficieryList /> } />
          <Route path="/admin/All-training-reportList" element={ <AllTrainingReportList /> } />
          <Route path="/admin/generate-training-reportList" element={ <GenrateTrainingReportList /> } />
          <Route path="/admin/Create-training-report" element={ <CreateReport /> } />
          <Route path="/admin/Report-form" element={ <ReportForm /> } />
          <Route path="/admin/feedback-form" element={ <FeedbackForm /> } />
        </Route>

        {/* District Coordinator Layout */}
        <Route path="/district-coordinator" element={<DistrictCoordinator />}>
          <Route path="/district-coordinator/profile" element={<Profile />} />
          <Route path="/district-coordinator/rollout-plan" element={<RollOutPlanDC />} />
          <Route path="/district-coordinator/rollout-plan-details" element={< RolloutDetailsDC />} />
          <Route path="/district-coordinator/grampanchayat-details" element={ <ViewAssignDistrict2 /> } />
          <Route path="/district-coordinator/district-details" element={ <GrampanchayatTrainerList2 /> } />
          <Route path="/district-coordinator/view" element={<Trainerdetails2 />} />
          <Route path="/district-coordinator/approve-traning-details" element={ <ApprovedDocumentDC />   } />
          <Route path="/district-coordinator/rejected-traning-details" element={<RejectedTrainingDetails />} />
          <Route path="/district-coordinator/rejected-traning-details-view" element={<ViewRejectedItems />} />
          <Route path="/district-coordinator/approve-traning-details-view" element={<ViewApproveditemlistDC />} />
        </Route>

        {/* Quality Checker Layout */}
        <Route path="/QualityChecker" element={<QualityCheckerLayout />}>
        <Route path="/QualityChecker/profile" element={<Profile />} />       
          <Route
            path="/QualityChecker/assign-trainer"
            element={<AllAssignedTrainer />}
          />
          <Route
            path="/QualityChecker/upload-document"
            element={<UploadedDocument />}
          />
          <Route
            path="/QualityChecker/Approved-document"
            element={<ApprovedDocument />}
          />
          <Route
            path="/QualityChecker/view-trainer-docs"
            element={<ViewTrainerdocs />}
          />
          <Route
            path="/QualityChecker/edit-trainer-docs"
            element={<EditTrainerDocs />}
          />
          <Route
            path="/QualityChecker/view-trainer-rejected"
            element={<ViewAllRejectedlist />}
          />
          <Route
            path="/QualityChecker/view-rejected-item"
            element={<Viewrejecteditem />}
          />
          <Route
            path="/QualityChecker/view-approved-docs"
            element={<ViewApproveditemlist />}
          />
          <Route
            path="/QualityChecker/view-assign-district"
            element={<ViewAssignDistrict />}
          />
          <Route
            path="/QualityChecker/view-grampanchyat-trainerlist"
            element={<GrampanchayatTrainerList />}
          />
          <Route
            path="/QualityChecker/trainerDetails"
            element={<Trainerdetails />}
          />
        </Route>

      </Routes>
    </div>
  );
}

export default App;
