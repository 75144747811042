import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState } from 'react';

  const cityData = [
    {
        label: 'Akola',
        value: 'Akola'
    },
    {
        label: 'Pune',
        value: 'Pune'
    },
    {
        label: 'Beed',
        value: 'Beed'
    },
    {
        label: 'Kolhapur',
        value: 'Kolhapur'
    },
    {
        label: 'Mumbai',
        value: 'Mumbai'
    },
    {
        label: 'Nagpur',
        value: 'Nagpur'
    },
    {
        label: 'Sangali',
        value: 'Sangali'
    },
]

const TalukaData = [
    {
        label: 'Ambegoan',
        value: 'Ambegoan'
    },
    {
        label: 'Baramati',
        value: 'Baramati'
    },
    {
        label: 'Bhor',
        value: 'Bhor'
    },
    {
        label: 'Daund',
        value: 'Daund'
    },
    {
        label: 'Haveli',
        value: 'Haveli'
    },
    {
        label: 'Khed',
        value: 'Khed'
    },
    {
        label: 'Mulshi',
        value: 'Mulshi'
    },
]

const PanchayatData = [
    {
        label: 'ADEGAON',
        value: 'ADEGAON'
    },
    {
        label: 'AGARGAON',
        value: 'AGARGAON'
    },
    {
        label: 'BAJARWADA',
        value: 'BAJARWADA'
    },
    {
        label: 'CHINCHALA',
        value: 'CHINCHALA'
    },
    {
        label: 'DHANORA',
        value: 'DHANORA'
    },
    {
        label: 'FARITPUR',
        value: 'FARITPUR'
    },
    {
        label: 'ITALAPUR',
        value: 'ITALAPUR'
    },

]

const TrainerData = [
    {
        Name: 'ABC',
        Mobile: 8529637410,
        Email: 'ABC@123gmail.com',
        Address: 'Pune'
    },
    {
        Name: 'PQR',
        Mobile: 789456130,
        Email: 'PQR@123gmail.com',
        Address: 'Pune'
    },
    {
        Name: 'XYZ',
        Mobile: 3216974036,
        Email: 'XYZ@123gmail.com',
        Address: 'Pune'
    },
    {
        Name: 'LMN',
        Mobile: 789456130,
        Email: 'LMN@123gmail.com',
        Address: 'Pune'
    },
]

export const AssignedTrainerList = ({ getAllTalukaData,
    getAllDistrictData,
    userDetails,
    getAllgrampanchayatdata, }) => {

    const classes = useStyles()

    //data variables
    const [city, setCity] = useState('')
    const [taluka, setTaluka] = useState('')
    const [panchayat, setPanchayat] = useState('')
    const [trainerData, setTrainerData] = useState([])

    //Error Variables
    const [cityError, setcityError] = useState(false)
    const [TalukaError, setTalukaError] = useState(false)
    const [PanchayatError, setPanchayatError] = useState(false)

    //Table Pagination variables
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //Table Pagination Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const validateDate = () => {

        let IsformValid = true;

        if (!city) {
            IsformValid = false;
            setcityError(true)
        }
        if (!taluka) {
            IsformValid = false;
            setTalukaError(true)
        }
        if (!panchayat) {
            IsformValid = false;
            setPanchayatError(true)
        }

        return IsformValid ;
    }

    //GET Trianer data
    const getTrainerData = () => {
        setcityError(false)
        setTalukaError(false)
        setPanchayatError(false)

        if (validateDate()) {
            console.log("Setting Trainer data")
            setTrainerData(TrainerData)
        }
    }

    return (
        <div className={classes.main}>
            <center>
                <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }} >All Assigned Trainers</Typography>
            </center>
            <div style={{ display: 'flex', flexDirection: 'row',marginBottom:50 }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-Bank">City</InputLabel>
                    <Select
                        style={{ width: 250 }}
                        id="outlined-adornment-Bank"
                        label="City"
                        value={city}
                        onChange={(e) => { setCity(e.target.value) }}
                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                    >
                        {cityData && cityData.map((item) => {
                            return (
                                <MenuItem key={item?.label} value={item?.value}>
                                    {item?.label}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    {cityError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select City</span> : null}
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-Bank">Taluka</InputLabel>
                    <Select
                        style={{ width: 250 }}
                        id="outlined-adornment-Bank"
                        label="Taluka"
                        value={taluka}
                        onChange={(e) => {setTaluka(e.target.value) }}

                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                    >
                        {TalukaData && TalukaData.map((item) => {
                            return (
                                <MenuItem key={item?.label} value={item?.value}>
                                    {item?.label}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    {TalukaError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Taluka</span> : null}
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-Bank">Panchayat</InputLabel>
                    <Select
                        style={{ width: 250 }}
                        id="outlined-adornment-month"
                        label="Panchayat"
                        value={panchayat}
                        onChange={(e) => { setPanchayat(e.target.value) }}

                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                    >
                        {PanchayatData && PanchayatData.map((item) => {
                            return (
                                <MenuItem key={item?.label} value={item?.value}>
                                    {item?.label}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    {PanchayatError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Panchyat</span> : null}
                </FormControl>
                <Button sx={{ m: 1, marginRight: 3 }} variant='contained' style={{ fontSize: 14 }} onClick={() => getTrainerData()} >
                    Get Data
                </Button>
            </div>
            <Paper sx={{ overflow: 'hidden', width: '90%' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>SR No.</strong></TableCell>
                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Name</strong></TableCell>
                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Mobile</strong></TableCell>
                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Email-Id</strong></TableCell>
                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Address</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                trainerData.length > 0 ? trainerData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{index + 1}</TableCell>
                                            <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.Name}</TableCell>
                                            <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.Mobile}</TableCell>
                                            <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.Email}</TableCell>
                                            <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.Address}</TableCell>
                                        </TableRow>
                                    )
                                })
                                    : <TableRow>NO Data Found</TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    // count={count ? count : 0}
                    count={trainerData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '1200px',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '1400px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
}));

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedTrainerList)