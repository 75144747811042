import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Card, CircularProgress } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Image1 from "../../Assets/Image/satara_Mandave.JPG"
import Image2 from "../../Assets/Image/satara_Mandave_2.jpg"
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Videos from "../../Assets/Image/Video.png"
import DOC from "../../Assets/Image/doc.png"
import TextsmsIcon from '@mui/icons-material/Textsms';
require('../../App.css')

const categories = [
  {
    Name: 'PR',
  },
  {
    Name: 'SN',
  },
  {
    Name: 'GP',
  },
  {
    Name: 'SM',
  },
]

const activities = [
  'Progress',
  'Snacks',
  'Group Photo',
  'Study Material',
  'Notepad and Pen',
  'Attendance Sheet',
  'Feedback Form',
  'Training Outcome',
  'Training Agenda',
  'Study Material Proof',
  'Distribution of Ebook',
  'Beneficiary Book'
]

export const ClientPendingDocDetails = ({ userDetails, getCategories, getAllCategoryEvidence }) => {

  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const Trainer = location.state

  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [categories, setcategories] = useState([])
  const [AllCategories, setAllCategories] = useState([])
  const [commentModal, setCommentModal] = useState(false);
  const [showComment, setShowComment] = useState('');

  console.log("Data", Trainer)
  console.log("Details", userDetails)

  const getAllCategories = async () => {
    setLoader(true)
    const result = await getCategories({
      "grampanchayat_id": Trainer?.grampanchayat_id,
      "trainingModule_id": Trainer?.trainingModule_id
    })
    if (result) {
      console.log("Categories", result)
      setcategories(result)
      setLoader(false)
    }
  }

  const getAllEvidenceCategories = async () => {
    setLoader(true)
    const result = await getAllCategoryEvidence({
      "grampanchayat_id": Trainer?.grampanchayat_id,
      "trainingModule_id": Trainer?.trainingModule_id,
      "trainer_id": Trainer?.trainer_id
    })
    if (result) {
      setAllCategories(result)
      setLoader(false)
    }
  }

  useEffect(() => {
    getAllCategories()
    getAllEvidenceCategories()
  }, [])

  const openComment = (row) => {
    setShowComment(row);
    setCommentModal(!commentModal);
  }


  return (
    <div className={classes.main}>
      <Card className={classes.card} style={{ backgroundColor: 'whitesmoke' }}>
        <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
          <IconButton style={{ margin: 10, padding: 0 }}>
            <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#1d5a96' }} sx={{
            }} >Trainer Data</Typography>
          </IconButton>
        </div>
        <div>
          <Table>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Trainer Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.trainerName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>District Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.districtName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>GramPanchayat Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.grampanchayatName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Training Module Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.trainingModuleName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Support Trainer Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.trainerNameAll[1] ? Trainer?.trainerNameAll[1] : '-'}</TableCell>
            </TableRow>
            {Trainer?.trainerMobileAll.length > 0 ? Trainer?.trainerMobileAll.map((row, index) => {
              return (<TableRow>
                <TableCell style={{ fontSize: 20 }}>Trainier {index + 1} Mobile:</TableCell>
                <TableCell style={{ fontSize: 20 }}>{row !== null ? row : '-'}</TableCell>
              </TableRow>)
            }) : null}
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Assignment Date:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{moment(Trainer?.startDate).format("DD MMM YYYY")}</TableCell>
            </TableRow>
          </Table>
          <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>Categories</Typography>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50, flexWrap: 'wrap', padding: '0px 10%' }}>
            {loader ? <CircularProgress color="primary" /> : AllCategories !== null && AllCategories.length > 0 ? AllCategories.map((row) => {
              if (row?.isApproved === false) {
                return (
                  <div style={{ width: '90%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#1d5a96', margin: 10 }}>
                      <Typography style={{ fontSize: 24, fontWeight: 700, marginLeft: 10, color: 'white' }}> {row?.trainingCategoryName}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
                      {
                        row?.unprocess?.length > 0 && row?.unprocess.map((item) => {
                          if (item?.categoryDoc.split('/')[0] === 'image') {
                            if (item?.status === 'unprocess')
                              return (
                                <div className='imgContainer'>
                                  <div className='img'>
                                    <a target="_blank" href={item?.documentUrl}><img src={item?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                                  </div>
                                </div>
                              )
                          } else if (item?.categoryDoc.split('/')[0] === 'video') {
                            if (item?.status === 'unprocess')
                              return (
                                <div className='imgContainer'>
                                  <div className='img'>
                                    <a target="_blank" href={item?.documentUrl}><img src={Videos} style={{ height: '100%', width: '100%' }} /></a>
                                  </div>
                                </div>
                              )
                          } else {
                            if (item?.status === 'unprocess')
                              return (
                                <div className='imgContainer'>
                                  <div className='img'>
                                    <a target="_blank" href={item?.documentUrl}><img src={DOC} style={{ height: '100%', width: '100%' }} /></a>
                                  </div>
                                </div>
                              )
                          }
                        })
                      }
                      {
                        row?.rejected?.length > 0 && row?.rejected.map((item) => {
                          if (item?.categoryDoc.split('/')[0] === 'image') {
                            if (item?.status === 'reject')
                              return (
                                <div className='imgContainer'>
                                  {item.status === "reject" ? <div className='deleteIcon' >
                                    <IconButton size="large" aria-label="delete" onClick={e => {
                                      openComment(item.approveRejectComment)
                                    }}>
                                      <TextsmsIcon color="error" />
                                    </IconButton>
                                  </div> : null}
                                  <div className='img'>
                                    <a target="_blank" href={item?.documentUrl}><img src={item?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                                  </div>
                                </div>
                              )
                          } else if (item?.categoryDoc.split('/')[0] === 'video') {
                            if (item?.status === 'reject')
                              return (
                                <div className='imgContainer'>
                                  {item.status === "reject" ? <div className='deleteIcon' >
                                    <IconButton size="large" aria-label="delete" onClick={e => {
                                      openComment(item.approveRejectComment)
                                    }}>
                                      <TextsmsIcon color="error" />
                                    </IconButton>
                                  </div> : null}
                                  <div className='img'>
                                    <a target="_blank" href={item?.documentUrl}><img src={Videos} style={{ height: '100%', width: '100%' }} /></a>
                                  </div>
                                </div>
                              )
                          } else {
                            if (item?.status === 'reject')
                              return (
                                <div className='imgContainer'>
                                  {item.status === "reject" ? <div className='deleteIcon' >
                                    <IconButton size="large" aria-label="delete" onClick={e => {
                                      openComment(item.approveRejectComment)
                                    }}>
                                      <TextsmsIcon color="error" />
                                    </IconButton>
                                  </div> : null}
                                  <div className='img'>
                                    <a target="_blank" href={item?.documentUrl}><img src={DOC} style={{ height: '100%', width: '100%' }} /></a>
                                  </div>
                                </div>
                              )
                          }
                        })
                      }
                      {
                        row?.unprocess?.length === 0 && row?.rejected?.length === 0 && row?.approved.length === 0 ?
                          <Typography>NO Image Uploaded In this category</Typography> : null
                      }
                      {
                        row?.approved.length > 0 ? <Typography>Category Approval Pending</Typography> : null
                      }
                    </div>
                    <br />
                  </div>
                )
              } else {
                return (
                  <div style={{ width: '90%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#1d5a96', margin: 10 }}>
                      <Typography style={{ fontSize: 24, fontWeight: 700, marginLeft: 10, color: 'white' }}> {row?.trainingCategoryName}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
                      {
                        row?.approved.length === 0 || row?.approved.length > 0 ?
                          <Typography style={{ color: 'green' }}>This category is Already Approved</Typography> : null
                      }
                    </div>
                    <br />
                  </div>
                )
              }
            }) : <span>NO data found</span>}
          </div>
        </div>
        <center>
          <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)}>Back</Button>
          <Button variant="contained" style={{ margin:20 }} onClick={ () => { getAllEvidenceCategories();getCategories() } } >{ loader ? <CircularProgress style={{ color:'white' }} /> : 'Reload' }</Button>
          {/* <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate("/admin/view-trainer-docs", {
            state: Trainer,
          })}>Verify Documents</Button> */}
        </center>
      </Card>

      <Modal
        className={classes.middlePosition}
        open={openModal}
        onClose={(e) => {
          e.preventDefault();
          setOpenModal(!openModal);
        }}
      >
        <Paper className={classes.profilemodal} style={{ marginTop: 70 }}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Activity</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setOpenModal(!openModal);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px', }}>Activity</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>RCID</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Category</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Gram Panchayat</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Activity Date</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>Activity_Name</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>RCID_Number</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>Category_Name</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>Gram_Panchayat</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>12/12/2022</div>
            </div>
          </div>
          <h3>Images</h3>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
            <div className='imgContainer'>
              <div className='img'>
                <a target="_blank" href={Image1}><img src={Image1} style={{ height: '100%', width: '100%' }} /></a>
              </div>
            </div>
            <div className='imgContainer'>
              <div className='img'>
                <a target="_blank" href={Image2}><img src={Image2} style={{ height: '100%', width: '100%' }} /></a>
              </div>
            </div>
          </div>
        </Paper>
      </Modal>

      <Modal
        className={classes.middlePosition}
        open={commentModal}
        onClose={(e) => {
          e.preventDefault();
          // setOpenModal(!openModal);
        }}
      >
        <Paper className={classes.passmodal2}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant='h6' component='div'>Reject Comment</Typography>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setCommentModal(!commentModal);
            }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ display:'flex',alignItems:'center' }}>
            {showComment}
          </div>
          <center>
            <Button sx={{ m: 1, marginRight: 3 }} variant='contained' style={{ fontSize: 14 }} onClick={() => { setCommentModal(!commentModal); }} >
              OK
            </Button>
          </center>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '70%',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal2: {
    width: '30%',
    // height: '20%',
    background: 'white',
    // borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '1000px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
  image: {
    width: 150,
    height: 150
  },
  imageApprove: {
    backgroundColor: 'green',
    color: 'white'
  },
  imageReject: {
    backgroundColor: 'red',
    color: 'white'
  }
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCategories: (data) => dispatch(Action.getCategories(data)),
    getAllCategoryEvidence: (data) => dispatch(Action.getAllCategoryEvidence(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPendingDocDetails)